<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row class="ma-0 pa-0 fill-height">
      <v-col cols="6" class="d-flex justify-start align-center py-0 presize-padding left">
        <div>
          <h1 class="headline-text pt-sans" v-html="'Arbeitschutzportal \n FÜR Stephan'"></h1>
          <app-button-component class="my-6" text="Losungen"/>
        </div>
      </v-col>
      <v-col cols="6" class="pa-0">
        <v-img :height="$vuetify.breakpoint.height - 96"
               src="@/assets/soldiers.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="mx-0 pa-0"
           :style="`margin-bottom: ${$vuetify.breakpoint.height / 10}px; margin-top: ${$vuetify.breakpoint.height / 10}px;`">
      <v-col cols="6" class="d-flex justify-start align-start py-lg-16 presize-padding left">
        <div>
          <h2 class="headline-text pt-sans">über uns</h2>
        </div>
      </v-col>
      <v-col cols="6" class="d-flex justify-start align-start pl-0 py-lg-16 presize-padding right">
        <div>
          <article class="article-text pt-sans">
            BeLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
          </article>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex justify-center align-center py-0 mx-auto presize-padding horizontal">
        <v-img contain src="@/assets/line.png"></v-img>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0 tinted-background">
      <v-col cols="12" class="ma-0 pa-0">
        <v-card flat tile
                :width="$vuetify.breakpoint.width"
                :min-height="$vuetify.breakpoint.height"
                class="presize-padding all"
                color="#F9F9FA">
          <v-card-title class="headline-text px-0">dein dashboard</v-card-title>
          <v-card-text class="pa-0">
            <div class="px-0 ma-0 dashboard-card-container">
              <div v-for="card of cards" :key="card.id" class="dashboard-card"
                   @click="$router.push({
                   name: 'Page',
                   params: { page: card.text.toLowerCase().replace(' ', '-'),
                   items: card.subcards,
                   number: `${getPart(card.id) ? 0 : ''}${card.id}`, title: card.text }
                   })">
                <div class="dashboard-card-number">{{ getPart(card.id) ? 0 : '' }}{{ card.id }}</div>
                <span class="dashboard-card-text">{{ card.text }}</span>
                <v-btn width="29" height="16"
                       class="dashboard-card-arrow"
                       text :ripple="false"
                       @click="$router.push({ name: 'Page', params: { page: card.text.toLowerCase().replace(' ', '-'), items: card.subcards, number: `${getPart(card.id) ? 0 : ''}${card.id}`, title: card.text } })">
                  <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5423 0.111004C19.8981 0.406991 19.5957 1.2066 19.8888 1.83884C19.965 2.00322 20.7694 2.86004 22.2365 4.33947C23.4639 5.57725 24.4681 6.61121 24.4681 6.63722C24.4681 6.66316 19.1734 6.68442 12.702 6.68442H0.935987L0.663505 6.82991C0.24446 7.05369 0.028601 7.41038 0.00270038 7.92195C-0.0228911 8.42739 0.132574 8.78815 0.505011 9.08795L0.742567 9.27915L12.6053 9.29528C19.1299 9.3041 24.4681 9.33242 24.4681 9.35812C24.4681 9.38382 23.4526 10.4316 22.2113 11.6866C19.7752 14.1496 19.7225 14.2166 19.7849 14.7768C19.8265 15.1501 20.0919 15.5906 20.399 15.7961C20.7443 16.0273 21.3151 16.0681 21.6606 15.8862C21.9679 15.7245 28.7425 8.86106 28.8919 8.56013C29.044 8.25395 29.0343 7.71944 28.8709 7.40344C28.6819 7.03768 21.9084 0.214481 21.6112 0.0903714C21.3046 -0.037677 20.8466 -0.0287985 20.5423 0.111004Z" fill="#D8BC76"/>
                  </svg>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-card flat tile
                :width="$vuetify.breakpoint.width"
                :height="$vuetify.breakpoint.height"
                class="pa-0 ma-0">
          <div class="under-dashboard-block">
            <div class="colored-area presize-padding all">
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-card-title class="headline-text colors gold">header h2</v-card-title>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <article class="article-text colors white--text">
                    <p class="mb-16">BeLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p class="mb-16">BeLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  </article>
                </v-col>
              </v-row>
            </div>
            <div class="image-area presize-padding right half">
              <v-img contain src="@/assets/soldier.png"></v-img>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-0 pa-0"
           :style="`margin-bottom: ${$vuetify.breakpoint.height / 5}px; margin-top: ${$vuetify.breakpoint.height / 5}px;`">
      <v-col cols="6" class="d-flex justify-start align-start py-lg-16 presize-padding left">
        <div>
          <h2 class="headline-text pt-sans">header h2</h2>
        </div>
      </v-col>
      <v-col cols="6" class="d-flex justify-start align-start pl-0 py-lg-16 presize-padding right">
        <div>
          <article class="article-text pt-sans">
            BeLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
          </article>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex justify-center align-center py-0 mx-auto presize-padding horizontal">
        <v-img contain src="@/assets/doctor.png"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  methods: {
    getPart (number) {
      return number / 10 < 1
    }
  },
  computed: {
    cards () {
      return this.$store.getters.GET_CARDS
    }
  }
}
</script>
<style scoped lang="scss">
.dashboard-card-container {
  display: grid;
  grid-template-rows: repeat(2, 178px);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
  padding-top: 60px;
  padding-bottom: 0;
  & .dashboard-card {
    position: relative;
    border: 2px solid #18283B;
    color: #18283B;
    padding: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color .45s ease-in-out;
    & .dashboard-card-text {
      font-family: 'PT Sans', sans-serif;
      font-weight: bold;
      font-size: 24px;
      line-height: 160%;
      color: #18283B;
      transition: color .45s ease-in-out;
      white-space: pre-line;
      text-align: center;
    }
    & .dashboard-card-arrow {
      opacity: 0;
      position: absolute;
      left: 86.73%;
      top: 83.15%;
      transition: opacity .45s ease-in-out;
      &:hover {
        &::before {
          background: transparent !important;
        }
      }
      @media (min-width: 1296px)  {
        left: 80.73%;
      }
      @media (min-width: 1902px)  {
        left: 86.73%;
      }
    }
    & .dashboard-card-number {
      font-family: 'PT Sans', sans-serif;
      font-weight: bold;
      position: absolute;
      left: 3.09%;
      top: -7.5%;
      color: #18283B;
      opacity: 0.1;
      font-size: 100px;
      z-index: 2;
      transition: .45s ease-in-out;
    }
    &:hover {
      background-color: #18283B;
      cursor: pointer;
      & .dashboard-card-number {
        color: #D8BC76;
        opacity: 1;
      }
      & .dashboard-card-arrow {
        opacity: 1;
      }
      & .dashboard-card-text {
        color: #FFF;
      }
    }
  }
}

.under-dashboard-block {
  display: grid;
  grid-template-columns: repeat(2, 50vw);
  grid-template-rows: repeat(2, 40vh) 20vh;
  & .colored-area {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
    background-color: #18283B;
  }
  & .image-area {
    grid-row: 2 / span 2;
    grid-column: 1 / 2;
  }
}
</style>